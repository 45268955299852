<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA SOBRE ACTIVIDADES AMBIENTALES:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-file-signature fa-3x"></i> <br>
                            <span class="lg-numero">{{listaActividades.length}}</span>
                            <br>
                            <span class="text-muted">Actividades</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de Actividades</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col md="6" class="my-2">
                            <b-button block variant="custom" :to="{name: 'Evaluación 14001'}">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br>Volver
                            </b-button>
                        </b-col>
                        <b-col v-if="checkPermissions('004-14001-EVA-ACT','c')==1"  md="6" class="my-2">
                            <b-button block @click="modalRegistrarActividad = true" variant="custom">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nueva actividad
                            </b-button>
                        </b-col>

                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Actividades registradas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaActividades" :fields="campoActividades" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button  v-if="checkPermissions('004-14001-EVA-ACT','u')==1"  @click="abrirModalActualizarActividad(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-button>
                                    <b-button  v-if="checkPermissions('004-14001-EVA-ACT','d')==1" @click="eliminarActividad(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :closeOnBackdrop="false" color="primario" :show.sync="modalRegistrarActividad">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nueva actividad </span>
            </h6>
            <CButtonClose @click="modalRegistrarActividad = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarActividad)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="proceso" rules="required" v-slot="{errors}">
                            <b-form-group label="Proceso:" class="mb-2">
                                <v-select :reduce="comboProceso =>comboProceso.idProceso" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActividad.idProceso , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActividad.idProceso" :options="comboProceso" @search:blur="blurProceso">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Procesos 14001'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model="datosActividad.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12">
                        <validation-provider name="descrición" :rules="{ }" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea type="text" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese la descripción" v-model="datosActividad.descripcion"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarActividad = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" color="primario" :show.sync="modalActualizarActividad">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar proceso </span>
            </h6>
            <CButtonClose @click="modalActualizarActividad = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarActividad)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="proceso" rules="required" v-slot="{errors}">
                            <b-form-group label="Proceso:" class="mb-2">
                                <v-select :reduce="comboProceso =>comboProceso.idProceso" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarActividad.idProceso , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarActividad.idProceso" :options="comboProceso" @search:blur="blurActualizarProceso">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Procesos 14001'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model="datosActualizarActividad.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12">
                        <validation-provider name="descripción" :rules="{ }" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea type="text" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese la descripción" v-model="datosActualizarActividad.descripcion"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarActividad = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            campoActividades: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, , {
                    key: "proceso",
                    label: "Proceso",
                    class: "text-center",
                }, {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaActividades: [],

            modalRegistrarActividad: false,
            modalActualizarActividad: false,

            datosActividad: {
                idProceso: '',
                nombre: '',
                descripcion: ''
            },
            datosActualizarActividad: {
                idActividad: '',
                idProceso: '',
                nombre: '',
                descripcion: ''
            },
            comboProceso: [],
            datosSession: {
                idCliente: ''
            },
            disabled: false,

        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        listarActividadesSGMA() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-actividades-sgma", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    }
                })
                .then(function (response) {
                    me.listaActividades = response.data;
                    me.totalRows = me.listaActividades.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarProcesoSGMA() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-procesos-sgma", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    }
                })
                .then(function (response) {
                    me.comboProceso = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarActividad() {
            let me = this;
            me.disabled = true;

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-actividad-sgma", {
                        datosActividad: me.datosActividad,
                        idCliente: me.datosSession.idCliente,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarActividadesSGMA();
                    me.modalRegistrarActividad = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        actualizarActividad() {
            let me = this;
            me.disabled = true;

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-actividad-sgma", {
                        datosActividad: me.datosActualizarActividad,
                        idCliente: me.datosSession.idCliente,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarActividadesSGMA();
                    me.modalActualizarActividad = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },

        abrirModalActualizarActividad(param) {
            this.datosActualizarActividad.idActividad = param.item.idActividad;
            this.datosActualizarActividad.idProceso = param.item.idProceso;
            this.datosActualizarActividad.nombre = param.item.nombre;
            this.datosActualizarActividad.descripcion = param.item.descripcion;
            this.modalActualizarActividad = true;
        },

        eliminarActividad(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la actividad?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-actividad-sgma", {
                            idActividad: param.item.idActividad,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarActividadesSGMA();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        resetModalRegistrarActividad() {

            this.datosActividad.nombre = '';
            this.datosActividad.descripcion = '';
            this.datosActividad.idProceso = null;
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
        },
        resetModalActualizarActividad() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
        blurProceso() {
            this.computedForm.refs.proceso.validate();
        },

        blurActualizarProceso() {
            this.computedActualizarForm.refs.proceso.validate();
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedActualizarForm() {
            return this.$refs.observer2;
        }
    },
    watch: {
        modalRegistrarActividad: function (val) {
            if (val == false) {
                this.resetModalRegistrarActividad();
            }
        },
        modalActualizarActividad: function (val) {
            if (val == false) {
                this.resetModalActualizarActividad();
            }
        },
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarProcesoSGMA();
            this.listarActividadesSGMA();
        }
    }

}
</script>
